<template>
  <div id="app">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="ym-mobile-wrap">
      <div class="container ym-container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <!-- <Mobilebar v-if="isAuthenticated" /> -->
            <div class="ym-logo">
              <h1><router-link to="/" :style="{'background-image': `url(${storage?.content}${logo?.content})`}">serwisexcces</router-link></h1>
            </div>
            <!-- <section class="section" v-if="isAuthenticated">
              <div class="userlogout"><span v-if="user">{{ user.firmname }}: {{ user.firstname }} {{ user.lastname }}</span><a @click="logout"><icon name="log-out"></icon></a></div>
              <portal to="BreadCrumbs"></portal>
            </section>                 -->
          </div>
        </div>
        <router-view/>
      </div>
    </div>
  </div>
</template>


<script>
  // import Mobilebar from '@/components/Mobilebar.vue'
  // import {mapGetters} from 'vuex'
  import {mapActions, mapGetters} from 'vuex'
  // import { $axios } from '@/utils/axios'
  // import store from './store'

  // import {AUTH_LOGOUT} from '@/store/actions/auth'
  // import { $axios } from '@/utils/axios'


  export default {
    name: 'app',
    components: {
      // Mobilebar
    },    
    data() {
      return {
        
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
        storage: 'storage',
        logo: 'logo',
        // isAuthenticated: 'auth/isAuthenticated',
        // user: 'auth/user'
      })
    },      
    mounted() {

    },
    created: function () {
      this.getAppSettings()

      // let isAuthenticated = this.isAuthenticated
      // $axios.interceptors.response.use(function (response) {
      //   return response;
      // }, function (err) {
      //   if(err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest){
      //     if(isAuthenticated) {
      //       store.dispatch('auth/authLogout').then(() => {
      //         location.reload()
      //       })
      //     }
      //   }
      //   return Promise.reject(err.response);
      // });      
    },  
    methods: {
      ...mapActions({
        getAppSettings: 'getAppSettings',
      }),  
      // ...mapActions({
      //   authLogout: 'auth/authLogout'
      // }),  
      // logout: function () {
      //   this.authLogout().then(() => location.reload())
      // }
    }    
  }

</script>